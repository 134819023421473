import {InvestigationCategory} from '@classes/investigationCategory';
import {prodaApiEnabled, ProdaApiEnabled} from "@classes/prodaApiEnabled";

/**
 * Plan Manager options suitable for sending to public-facing sections of the app.
 * Excludes some fields that are required for administrators.
 */
export interface PlanManagerOptions {
  showTwitterFeed: boolean;
  showTwitterIcon: boolean;
  blogUrl: string;
  showBlogLink: boolean;
  twitterHandle: string;
  instagramHandle: string;
  showInstagramLink: boolean;
  facebookUsername: string;
  showFacebookLink: boolean;
  linkedInProfile: string;
  showLinkedInLink: boolean;
  theme: any;
  statementLogo: string;
  statementLogoWidth: number;
  statementLogoHeight: number;
}

// tslint:disable-next-line:no-namespace
export namespace PlanManagerOptions {
  export function parse(src: any): PlanManagerOptions {
    if (!src) {
      return undefined;
    }

    return {
      showTwitterFeed: src.showTwitterFeed,
      showTwitterIcon: src.showTwitterIcon,
      twitterHandle: src.twitterHandle,
      blogUrl: src.blogUrl,
      showBlogLink: src.showBlogLink,
      instagramHandle: src.instagramHandle,
      showInstagramLink: src.showInstagramLink,
      facebookUsername: src.facebookUsername,
      showFacebookLink: src.showFacebookLink,
      linkedInProfile: src.linkedInProfile,
      showLinkedInLink: src.showLinkedInLink,
      theme: Object.assign({}, src.theme),
      statementLogo: src.statementLogo,
      statementLogoWidth: src.statementLogoWidth,
      statementLogoHeight: src.statementLogoHeight
    };
  }

  export function toJSON(src: PlanManagerOptions): any {
    if (!src) {
      return undefined;
    }

    return {
      showTwitterFeed: src.showTwitterFeed,
      showTwitterIcon: src.showTwitterIcon,
      twitterHandle: src.twitterHandle,
      blogUrl: src.blogUrl,
      showBlogLink: src.showBlogLink,
      instagramHandle: src.instagramHandle,
      showInstagramLink: src.showInstagramLink,
      facebookUsername: src.facebookUsername,
      showFacebookLink: src.showFacebookLink,
      linkedInProfile: src.linkedInProfile,
      showLinkedInLink: src.showLinkedInLink,
      theme: Object.assign({}, src.theme),
      statementLogo: src.statementLogo,
      statementLogoWidth: src.statementLogoWidth,
      statementLogoHeight: src.statementLogoHeight
    };
  }
}

export interface AdminPlanManagerOptions extends PlanManagerOptions {
  onlyShowLinkedProviders: boolean;
  allowClientPaymentAuth: boolean;
  allowRemittanceAdvices: boolean;
  useInvestigationCategories: boolean;
  useServiceAgreements: boolean;
  buildMonthlyStatements: boolean;
  emailMonthlyStatements: boolean;
  allowRevelioProcessing: boolean;
  forceRevelioProcessing: boolean;
  investigationCategories: InvestigationCategory[];
  placeholderEntities: string[];
  prodaApiEnabled: ProdaApiEnabled;
  arithmancy?: {[key: string]: any}
}

// tslint:disable-next-line:no-namespace
export namespace AdminPlanManagerOptions {
  export function parse(src: any): AdminPlanManagerOptions {
    if (!src) {
      return undefined;
    }

    return Object.assign( PlanManagerOptions.parse(src), {
      onlyShowLinkedProviders: src.onlyShowLinkedProviders,
      allowClientPaymentAuth: src.allowClientPaymentAuth,
      allowRemittanceAdvices: src.allowRemittanceAdvices,
      useInvestigationCategories: src.useInvestigationCategories,
      useServiceAgreements: src.useServiceAgreements,
      buildMonthlyStatements: src.buildMonthlyStatements,
      emailMonthlyStatements: src.emailMonthlyStatements,
      allowRevelioProcessing: src.allowRevelioProcessing,
      forceRevelioProcessing: src.forceRevelioProcessing,
      investigationCategories: (src.investigationCategories || []).map( InvestigationCategory.parse ),
      placeholderEntities: src.placeholderEntities || [],
      prodaApiEnabled: prodaApiEnabled(src.prodaApiEnabled),
      arithmancy: Object.assign({}, src.arithmancy)
    });
  }

  export function toJSON(src: AdminPlanManagerOptions|Partial<AdminPlanManagerOptions>): any {
    if (!src) {
      return undefined;
    }

    return Object.assign( PlanManagerOptions.parse(src), {
      onlyShowLinkedProviders: src.onlyShowLinkedProviders,
      allowClientPaymentAuth: src.allowClientPaymentAuth,
      allowRemittanceAdvices: src.allowRemittanceAdvices,
      useInvestigationCategories: src.useInvestigationCategories,
      useServiceAgreements: src.useServiceAgreements,
      buildMonthlyStatements: src.buildMonthlyStatements,
      emailMonthlyStatements: src.emailMonthlyStatements,
      allowRevelioProcessing: src.allowRevelioProcessing,
      forceRevelioProcessing: src.forceRevelioProcessing,
      investigationCategories: (src.investigationCategories || []).map( InvestigationCategory.toJSON ),
      placeholderEntities: [...src.placeholderEntities],
      prodaApiEnabled: prodaApiEnabled(src.prodaApiEnabled),
      arithmancy: Object.assign({}, src.arithmancy)
    });
  }

  export function clone(src: AdminPlanManagerOptions): AdminPlanManagerOptions {
    if (!src) {
      return undefined;
    }

    return {
      prodaApiEnabled: ProdaApiEnabled.none,
      onlyShowLinkedProviders: src.onlyShowLinkedProviders,
      allowClientPaymentAuth: src.allowClientPaymentAuth,
      allowRemittanceAdvices: src.allowRemittanceAdvices,
      useInvestigationCategories: src.useInvestigationCategories,
      useServiceAgreements: src.useServiceAgreements,
      buildMonthlyStatements: src.buildMonthlyStatements,
      emailMonthlyStatements: src.emailMonthlyStatements,
      allowRevelioProcessing: src.allowRevelioProcessing,
      forceRevelioProcessing: src.forceRevelioProcessing,
      placeholderEntities: [...src.placeholderEntities],
      investigationCategories: (src.investigationCategories || []).map( InvestigationCategory.clone ),
      showTwitterFeed: src.showTwitterFeed,
      showTwitterIcon: src.showTwitterIcon,
      twitterHandle: src.twitterHandle,
      blogUrl: src.blogUrl,
      showBlogLink: src.showBlogLink,
      instagramHandle: src.instagramHandle,
      showInstagramLink: src.showInstagramLink,
      facebookUsername: src.facebookUsername,
      showFacebookLink: src.showFacebookLink,
      linkedInProfile: src.linkedInProfile,
      showLinkedInLink: src.showLinkedInLink,
      theme: Object.assign({}, src.theme),
      statementLogo: src.statementLogo,
      statementLogoWidth: src.statementLogoWidth,
      statementLogoHeight: src.statementLogoHeight,
      arithmancy: Object.assign({}, src.arithmancy)
    };
  }

  export function empty(): AdminPlanManagerOptions {
    return {
      prodaApiEnabled: ProdaApiEnabled.none,
      onlyShowLinkedProviders: undefined,
      allowClientPaymentAuth: undefined,
      allowRemittanceAdvices: undefined,
      useInvestigationCategories: undefined,
      useServiceAgreements: undefined,
      buildMonthlyStatements: undefined,
      emailMonthlyStatements: undefined,
      allowRevelioProcessing: undefined,
      forceRevelioProcessing: undefined,
      investigationCategories: [],
      placeholderEntities: [],
      showTwitterFeed: undefined,
      showTwitterIcon: undefined,
      twitterHandle: undefined,
      blogUrl: undefined,
      showBlogLink: undefined,
      instagramHandle: undefined,
      showInstagramLink: undefined,
      facebookUsername: undefined,
      showFacebookLink: undefined,
      linkedInProfile: undefined,
      showLinkedInLink: undefined,
      theme: {},
      statementLogo: undefined,
      statementLogoWidth: undefined,
      statementLogoHeight: undefined,
      arithmancy: {allowSubmit : false, forceSubmit : false}
    };
  }
}
