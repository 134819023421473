export enum API {
 system,
 admin,
 proda,
 remittance,
 supportcoordinator,
 user,
 invoices,
 plans,
 process,
 prodaapi,
 attachments,
 providers,
 dashboards,
 billing,
 reports,
 xero,
 filenotes,
 websocket,
 planmanager,
 triage,
 aba,
 creditsense,
 revelio,
 arithmancy,
 quidditch
}

export namespace API {
 const apiNames = new Map<API, string>([
  [API.system, 'system'],
  [API.admin, 'admin'],
  [API.proda, 'proda'],
  [API.remittance, 'remittance'],
  [API.supportcoordinator, 'supportcoordinator'],
  [API.user, 'user'],
  [API.invoices, 'invoices'],
  [API.plans, 'plans'],
  [API.process, 'process'],
  [API.prodaapi, 'prodaapi'],
  [API.attachments, 'attachments'],
  [API.providers, 'providers'],
  [API.dashboards, 'dashboards'],
  [API.billing, 'billing'],
  [API.reports, 'reports'],
  [API.xero, 'xero'],
  [API.filenotes, 'filenotes'],
  [API.websocket, 'websocket'],
  [API.planmanager, 'planmanager'],
  [API.triage, 'triage'],
  [API.aba, 'aba'],
  [API.revelio, 'revelio'],
  [API.creditsense, 'creditsense'],
  [API.arithmancy, 'arithmancy'],
  [API.quidditch, 'quidditch']
 ]);

  export function toString(api: API): string {
    return apiNames.get(api);
  }
}
