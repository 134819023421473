import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@services/auth.service';
import { Subscription } from "rxjs";
import { filter, first } from "rxjs/operators";
import { User, Admin } from "@classes/user";
import { Permission, Permissions } from "@classes/permissions";

/**
 * Angular route guard to allow a route to be activated only if the user is an administrator and has the required permissions.
 */
@Injectable()
export class AdminCanActivate implements CanActivate {

  public constructor(public authService: AuthService, public router: Router) {}

  private checkUserHasAllPermissions(user: Admin, requiredPermissions: Permission[]): boolean {
    // Return true if all of the requested permissions have been assigned to the current user
    try {
      const permissionIds = requiredPermissions.map( Permissions.getPermissionId );
      return permissionIds.every( permissionId => user.permissions.includes( permissionId ) );
    } catch (e) {
      return false;
    }
  }

	private checkUserHasAnyPermission(user: Admin, anyPermissions: Permission[]): boolean {

		// Return true if all of the requested permissions have been assigned to the current user
		try {
			const permissionIds = anyPermissions.map( Permissions.getPermissionId )
			return permissionIds.filter( permissionId => user.permissions.includes( permissionId ) ).length >= 1;
		}
		catch (e) {
			return false;
		}
	}

	/**
	* Return an array of permissions required in order to activate the route.
	* Child classes can override this method to handle nuanced cases (eg permissions are dependant on route params)
	*/
	public getRoutePermissions(route: ActivatedRouteSnapshot): Permission[] {
		// default to activatePermissions as that requires ALL matching permissions. Fall back to activatePermissionsAny as needed
		return route.data?.activatePermissions ? route.data?.activatePermissions : route.data?.activatePermissionsAll ?? [];
	}
	public getRoutePermissionsAll(route: ActivatedRouteSnapshot): Permission[] {
		return route.data?.activatePermissions ?? [];
	}
	public getRoutePermissionsAny(route: ActivatedRouteSnapshot): Permission[] {
		return route.data?.activatePermissionsAny ?? [];
	}

	public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
		return new Promise<boolean>((resolve, reject) => {

			// Subscribe to the "userLoaded" observable on the authService.
			// Filter out the initial value (undefined)
			// Use the "first()" operator to automatically unsubsribe once a value has been received.
			this.authService.userLoaded$.pipe(filter( x => x !== undefined ), first()).subscribe( async userLoaded => {

				// Redirect to the login page if the user isn't logged in.
				// Shouldn't ever run this bit of code, since earlier checks (eg AdminCanLoad) should have already run.
				if (!userLoaded) {
					this.router.navigate(["/login"]);
					return resolve(false);
				}

				// Make sure the user is an admin. Can't rely on the "AdminCanLoad" guard if we're swapping in and out of accounts
				// with different permission levels.
				if (!User.isAdmin(this.authService.currentUser)) {
					// this.router.navigate(["/forbidden"]);
					return resolve(false);
				}

				// If no particular permissions are specified for this route, go ahead and activate
				if (this.getRoutePermissionsAny(route).length > 0) {

					const routePermissions = this.getRoutePermissionsAny(route);
					if (routePermissions.length === 0) {
						return resolve(true);
					}

					// Otherwise, make sure the user has all of the permissions required
					try {
						const result = this.checkUserHasAnyPermission(this.authService.currentUser, routePermissions);
						if (!result) {
							this.router.navigate(["/forbidden"]);
						}
						resolve( result );
					}
					catch (e) {
						resolve(false);
					}
				} else {

					const routePermissions = this.getRoutePermissionsAll(route);
					if (routePermissions.length === 0) {
						return resolve(true);
					}

					// Otherwise, make sure the user has all of the permissions required
					try {
						const result = this.checkUserHasAllPermissions(this.authService.currentUser, routePermissions);
						if (!result) {
							this.router.navigate(["/forbidden"]);
						}
						resolve( result );
					}
					catch (e) {
						resolve(false);
					}
				}
			});

		});
  }
}
