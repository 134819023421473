const urlBase = "https://api.dev.darkarts.unidap.biz";
export const websocketEndpoint = "wss://jfz0jz3koj.execute-api.ap-southeast-2.amazonaws.com/dev";

/**
* AWS config for local VM development
*/
const awsSettings = {
	"Auth": {
		"region": "ap-southeast-2",
		"identityPoolId": "ap-southeast-2:da015b1a-445d-4c65-aee3-ffaff623f208",
		"userPoolId": "ap-southeast-2_R4iD68PoS",
		"userPoolWebClientId": "7v1igo10gi25cjg77f440mbknj"
	},
	"API": {
		"endpoints": [{
			"name": "system",
			"region": "ap-southeast-2",
			"endpoint": `${urlBase}/system/`
		}, {
			"name": "user",
			"region": "ap-southeast-2",
			"endpoint": `${urlBase}/user/`
		}, {
			"name": "supportcoordinator",
			"region": "ap-southeast-2",
			"endpoint": `${urlBase}/supportcoordinator/`
		}, {
			"name": "admin",
			"region": "ap-southeast-2",
			"endpoint": `${urlBase}/admin/`
		}, {
			"name": "proda",
			"region": "ap-southeast-2",
			"endpoint": `${urlBase}/proda/`
		}, {
			"name": "plans",
			"region": "ap-southeast-2",
			"endpoint": `${urlBase}/plans/`
		}, {
			"name": "attachments",
			"region": "ap-southeast-2",
			"endpoint": `${urlBase}/attachments/`
		}, {
			"name": "invoices",
			"region": "ap-southeast-2",
			"endpoint": `${urlBase}/invoices/`
		}, {
			"name": "providers",
			"region": "ap-southeast-2",
			"endpoint": `${urlBase}/providers/`
		}, {
			"name": "dashboards",
			"region": "ap-southeast-2",
			"endpoint": `${urlBase}/dashboards/`
		}, {
			"name": "billing",
			"region": "ap-southeast-2",
			"endpoint": `${urlBase}/billing/`
		}, {
			"name": "reports",
			"region": "ap-southeast-2",
			"endpoint": `${urlBase}/reports/`
		}, {
			"name": "xero",
			"region": "ap-southeast-2",
			"endpoint": `${urlBase}:30130/xero/`
		}, {
			"name": "superuser",
			"region": "ap-southeast-2",
			"endpoint": `${urlBase}:30140/superuser/`
		}, {
			"name": "statements",
			"region": "ap-southeast-2",
			"endpoint": `${urlBase}:30150/statements/`
		}, {
			"name": "filenotes",
			"region": "ap-southeast-2",
			"endpoint": `${urlBase}/filenotes/`
		}, {
			"name": "websocket",
			"region": "ap-southeast-2",
			"endpoint": `${urlBase}/ws/`
		}, {
			"name": "planmanager",
			"region": "ap-southeast-2",
			"endpoint": `${urlBase}/planmanager/`
		}, {
			"name": "triage",
			"region": "ap-southeast-2",
			"endpoint": `${urlBase}/triage/`
		}, {
			"name": "aba",
			"region": "ap-southeast-2",
			"endpoint": `${urlBase}/aba/`
		//}, {
		//	"name": "creditsense",
		//	"region": "ap-southeast-2",
		//	"endpoint": `${urlBase}/creditsense/`
		}, {
			"name": "revelio",
			"region": "ap-southeast-2",
			"endpoint": `${urlBase}/revelio/`
		},{
			"name": "prodaapi",
			"region": "ap-southeast-2",
			"endpoint": `${urlBase}/proda-api/`
		},{
			"name": "remittance",
			"region": "ap-southeast-2",
			"endpoint": `${urlBase}/remittance/`
		}, {
			"name": "process",
			"region": "ap-southeast-2",
			"endpoint": `${urlBase}/process-api/`
		}, {
			"name": "arithmancy",
			"region": "ap-southeast-2",
			"endpoint": `${urlBase}/arithmancy/`
		}, {
			"name": "quidditch",
			"region": "ap-southeast-2",
			"endpoint": `${urlBase}/quidditch/`
		}]
	}
};


export default awsSettings;
