import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { User, UserType } from '@classes/user';
import { Plan } from '@classes/plans';
import { FileNote } from '@classes/filenotes';
import { Provider } from '@classes/provider';
import { Invoice } from "@classes/invoices";
import { UserRelationship } from "@classes/linkedUser";
import { RestService, API } from '@services/rest.service';
import { Subscription, timer, Subject, BehaviorSubject } from 'rxjs';
import { StorageService } from '@services/storage.service';
import { v4 as uuidv4 } from 'uuid';
import { WebsocketState, BroadcastMessage, BroadcastMessageType, DeleteMessage, MaintenanceMessage, ExpireCacheMessage } from "@classes/websocketHelpers";
import { SimpleWaitQueue } from "@classes/waitQueue";
import { AuthService } from '@services/auth.service';
import { SessionStorageService } from "@services/storage.service";
import { websocketEndpoint } from "@root/aws-settings";
import {NotificationService } from '@services/notification.service'

enum WebsocketStatus {
	connecting = 0,
	open = 1,
	closing = 2,
	closed = 3
}

interface PromiseHandlers {
	"resolve": (any) => any,
	"reject": (any) => any
};

/**
* Lazy-loaded global service.
* Code won't be loaded unless user logs in to an admin account.
*/
@Injectable({ "providedIn": "root"})
export class WebSocketService {

	//private static readonly heartbeatConnectAfter: number = 3000; // Delay until first ping is sent, 3 seconds - test only
	//private static readonly heartbeatInterval: number = 3000; // Ping every 3 seconds - test only
	private static readonly heartbeatConnectAfter: number = 15000; // Delay until first ping is sent, 15 seconds
	private static readonly heartbeatInterval: number = 55000; // Ping every 55 seconds

	private static readonly websocketApi = API.websocket;

	private static callbackMap: Map<string, PromiseHandlers> = new Map<string, PromiseHandlers>();

	private heartbeat = timer(WebSocketService.heartbeatConnectAfter, WebSocketService.heartbeatInterval);
	private heartbeatSubscription: Subscription;

	private _waitQueue = new SimpleWaitQueue<void>();

	public readonly connected$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	constructor(private restService: RestService, private authService: AuthService, private router: Router) {
		this.authService.userLoaded$.subscribe( this.userLoaded.bind(this) )
	}

	private userLoaded(isLoggedIn: boolean) {
		if (isLoggedIn && this.authService.isAdmin) {
			this.heartbeatSubscription = this.heartbeat.subscribe( () => { this.send("ping"); });
			this.initSocket();
		}
		else {
			this.heartbeatSubscription?.unsubscribe();
			if (this._websocket && [WebsocketStatus.connecting, WebsocketStatus.open].includes(this._websocket.readyState)) {
				this._websocket.close();
			}
		}
	}

	private _websocket: WebSocket;
	private _socketOpen: boolean = false;
	private _msgQueue: any[] = [];

	private objectSource = new Subject<BroadcastMessage>();
	public readonly objectSource$ = this.objectSource.asObservable();

	private socketInitFunc(): Promise<void> {
		return new Promise<void>( async (resolve, reject) => {

			try {

				const response = await this.restService.get(WebSocketService.websocketApi, 'ticket');
				const ticket = response.ticket;

				this._websocket = new WebSocket(websocketEndpoint, ticket);
				this._websocket.addEventListener("close", e => this.socketClosed(e) );
				this._websocket.addEventListener("error", e => this.socketClosed(e) );
				this._websocket.addEventListener("message", e => this.messageReceived(e) );
				this._websocket.addEventListener("open", async () => {

					// Resolve after a slight delay to give the websocket service time to sort it's life out.
					// Not sure if issue is caused by bugs in serverless-offline implementation of websocket handler, or
					// my own mistakes, but the $connect function appears to be:
					// a) Ignoring any attempt to execute a custom authoriser
					// b) Allowing the websocket's "onOpen" event to fire before the $connect handler has completed
					setTimeout( async () => {
						WebsocketState.isOpen = true;
						await StorageService.entities.clear();
						this.connected$.next(true);
						resolve();
					}, 300 );

				} );
			}
			catch (e) {

				reject(e);
			}
		});
	}

	private initSocket(): Promise<void> {

		if (this.socketOpen) {
			return Promise.resolve();
		}

		return this._waitQueue.enqueue( this.socketInitFunc.bind(this) );
	}

	private get socketOpen(): boolean {
		return this._websocket?.readyState === WebsocketStatus.open;
	}

	private socketClosed(e) {
		this.connected$.next(false);
		StorageService.entities.clear();
		WebsocketState.isOpen = false;
		this._websocket = undefined;
	}

	private async broadcastEvent(dataType: BroadcastMessageType, data: any) {
		switch (dataType) {
			case "maintenance":
				const maintenanceMsg = <MaintenanceMessage>BroadcastMessage.from("maintenance", data);
				this.authService.maintenanceMode = maintenanceMsg.enabled;

				if (!User.isAdmin(this.authService.currentUser) || !this.authService.currentUser.marauder) {
					// Redirect to the "Maintenance" page if we're not a marauder
					this.router.navigate(maintenanceMsg.enabled ? ["/maintenance"] : [this.authService.usersHomePage] );
				}
				this.objectSource.next( maintenanceMsg );
				break

			case "expireCache":
				const msg = <ExpireCacheMessage>BroadcastMessage.from("expireCache", data);
				StorageService.general.remove(msg.cache);
				break

			case "delete":
				const message = <DeleteMessage>BroadcastMessage.from("delete", data);
				await StorageService.entities.remove(message.entityId);
				this.objectSource.next( message );
				break

			case "user":
				const user = User.parse(data);
				await StorageService.entities.set(User.toJSON(user));
				this.objectSource.next( BroadcastMessage.from("user", user) );
				break;

			case "plan":
				const plan = Plan.parse(data);
				StorageService.entities.set(Plan.toJSON(plan));
				this.objectSource.next( BroadcastMessage.from("plan", plan) );
				break;

			case "filenote":
				const filenote = FileNote.parse(data);
				StorageService.entities.set(FileNote.toJSON(filenote));
				this.objectSource.next( BroadcastMessage.from("filenote", filenote) );
				break;

			case "provider":
				const provider = Provider.parse(data);
				StorageService.entities.set(Provider.toJSON(provider));
				this.objectSource.next( BroadcastMessage.from("provider", provider) );
				break;

			case "invoice":
				const invoice = Invoice.parse(data);
				StorageService.entities.set(Invoice.toJSON(invoice));
				this.objectSource.next( BroadcastMessage.from("invoice", invoice) );
				break;

			case "linked_user":
				const relationship = UserRelationship.parse(data);
				StorageService.entities.set(UserRelationship.toJSON(relationship));
				this.objectSource.next( BroadcastMessage.from("linked_user", relationship) );
				break;

			case "arithmancy":
				this.objectSource.next( BroadcastMessage.from("arithmancy", data) );
				break;

			default:
				break;
		}
	}

	private messageReceived(response) {
		const data = JSON.parse(response.data);
		if (data.requestId) {
			const promiseHandlers = WebSocketService.callbackMap.get(data.requestId);
			WebSocketService.callbackMap.delete(data.requestId);
			if (data.error) {
				promiseHandlers?.reject(data.error);
			}
			else {
				if (!!data.response && !!data.response.ping) {
					const indexedDBVersion = data.response.dbVersion;
					if (StorageService.dbVersion != indexedDBVersion) {
						console.log(`Clearing indexedDb: should be ${indexedDBVersion}, but is ${StorageService.dbVersion}`);
						SessionStorageService.clear();

						StorageService.entities.clear();
						StorageService.general.clear();
						StorageService.supports.clear();
						StorageService.nonVolatile.clear();
						NotificationService.error('Cache Error', 'Your local cache is out of date and has been cleared - please refresh your browser', 0);
					}

				}
				promiseHandlers?.resolve(data.response);
			}
		}
		else {
			this.broadcastEvent(data.type, data.response);
		}
	}

	public async notify(method: string, payload?: any) {
		try {
			await this.initSocket();

			// Include the plan manager ID if we're impersonating one.
			const planManager = SessionStorageService.get("planManager");

			const data = {
				"method": method,
				"payload": payload,
				"x-pm": planManager?.id
			};

			this._websocket.send(JSON.stringify(data));
		}
		catch (e) {
			console.log(e);
		}
	}

	public send(method: string, payload?: any): Promise<any> {
		return new Promise( async (resolve, reject) => {

			try {
				await this.initSocket();

				// Include the plan manager ID if we're impersonating one.
				const planManager = SessionStorageService.get("planManager");

				// Generate a unique request identifier
				const requestId = uuidv4();

				WebSocketService.callbackMap.set(requestId, { "resolve": resolve, "reject": reject });
				const data = {
					"method": method,
					"payload": payload,
					"requestId": requestId,
					"x-pm": planManager?.id
				};

				this._websocket.send(JSON.stringify(data));

			}
			catch (e) {
				reject(e);
			}
		});
	}

	public close() {
		this._websocket.close();
	}
}
