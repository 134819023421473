import { TriageLevel } from "@classes/triageLevel";

export interface ProviderTriageKeyword {
	"triageKeywordId": string;
	"keyword": string;
	"triageSubject": boolean;
	"revelioSubject": boolean;
	"revelioFilename": boolean;
	"providerTriageKeywordId": string;
	"providerTriageSubject": boolean;
	"providerRevelioSubject": boolean;
	"providerRevelioFilename": boolean;
}

export namespace ProviderTriageKeyword {
	export function parse(src: any): ProviderTriageKeyword {
		if (!src) {
			return undefined;
		}

		return {
			"triageKeywordId": src.triageKeywordId,
			"keyword": src.keyword,
			"triageSubject": src.triageSubject,
			"revelioSubject": src.revelioSubject,
			"revelioFilename": src.revelioFilename,
			"providerTriageKeywordId": src.providerTriageKeywordId,
			"providerTriageSubject": src.providerTriageSubject,
			"providerRevelioSubject": src.providerRevelioSubject,
			"providerRevelioFilename": src.providerRevelioFilename
		};
	}

	export function toJSON(src: ProviderTriageKeyword): any {
		return {
			"triageKeywordId": src?.triageKeywordId,
			"keyword": src?.keyword,
			"triageSubject": src?.triageSubject,
			"revelioSubject": src?.revelioSubject,
			"revelioFilename": src?.revelioFilename,
			"providerTriageKeywordId": src?.providerTriageKeywordId,
			"providerTriageSubject": src?.providerTriageSubject,
			"providerRevelioSubject": src?.providerRevelioSubject,
			"providerRevelioFilename": src?.providerRevelioFilename
		};
	}

	export function clone(src: ProviderTriageKeyword): ProviderTriageKeyword {
		return !!src ? {...src} : undefined;
	}
}

export interface ProviderAutoTriage {
	providerId: string;
	fromEmailName: string;
	invoiceNumberPattern: string;
	triageLevel?: TriageLevel;
	planManager: string;
	autoTriageEnabled: boolean;
	revelioEnabled: boolean;
	arithmancyEnabled: boolean;
	revelioForceSubmit: boolean;
	arithmancyForceSubmit: boolean;
	revelioAllowSubmit: boolean;
	arithmancyAllowSubmit: boolean;
	providerTriageKeyword: ProviderTriageKeyword[];
}

export namespace ProviderAutoTriage {

	export function parse(src: any): ProviderAutoTriage {
		if (!src) {
			return undefined;
		}

		return {
			"providerId": src.providerId,
			"fromEmailName": src.fromEmailName,
			"invoiceNumberPattern": src.invoiceNumberPattern,
			"triageLevel": TriageLevel.parse(src.triageLevel),
			"planManager": src.planManager,
			"autoTriageEnabled": src.autoTriageEnabled,
			"revelioEnabled": src.revelioEnabled,
			"arithmancyEnabled": src.arithmancyEnabled,
			"revelioForceSubmit": src.revelioForceSubmit,
			"arithmancyForceSubmit": src.arithmancyForceSubmit,
			"revelioAllowSubmit": src.revelioAllowSubmit,
			"arithmancyAllowSubmit": src.arithmancyAllowSubmit,
			"providerTriageKeyword": (src.providerTriageKeyword || []).map( ProviderTriageKeyword.parse )
		};
	}

	export function toJSON(src: ProviderAutoTriage): any {
		if (!src) {
			return undefined;
		}

		return {
			"providerId": src.providerId,
			"fromEmailName": src.fromEmailName,
			"invoiceNumberPattern": src.invoiceNumberPattern,
			"triageLevel": TriageLevel.toPostgresEnum(src.triageLevel),
			"planManager": src.planManager,
			"autoTriageEnabled": src.autoTriageEnabled,
			"revelioEnabled": src.revelioEnabled,
			"arithmancyEnabled": src.arithmancyEnabled,
			"revelioForceSubmit": src.revelioForceSubmit,
			"arithmancyForceSubmit": src.arithmancyForceSubmit,
			"revelioAllowSubmit": src.revelioAllowSubmit,
			"arithmancyAllowSubmit": src.arithmancyAllowSubmit,
			"providerTriageKeyword": (src.providerTriageKeyword || []).map( ProviderTriageKeyword.toJSON )
		};
	}

	export function clone(src: ProviderAutoTriage): ProviderAutoTriage {
		return {
			"providerId": src.providerId,
			"fromEmailName": src.fromEmailName,
			"invoiceNumberPattern": src.invoiceNumberPattern,
			"triageLevel": src.triageLevel,
			"planManager": src.planManager,
			"autoTriageEnabled": src.autoTriageEnabled,
			"revelioEnabled": src.revelioEnabled,
			"arithmancyEnabled": src.arithmancyEnabled,
			"revelioForceSubmit": src.revelioForceSubmit,
			"arithmancyForceSubmit": src.arithmancyForceSubmit,
			"revelioAllowSubmit": src.revelioAllowSubmit,
			"arithmancyAllowSubmit": src.arithmancyAllowSubmit,
			"providerTriageKeyword": (src.providerTriageKeyword || []).map( ProviderTriageKeyword.clone )
		}
	}

	export function blank(): ProviderAutoTriage {
		return {
			"providerId": undefined,
			"fromEmailName": undefined,
			"invoiceNumberPattern": undefined,
			"triageLevel": undefined,
			"planManager": undefined,
			"autoTriageEnabled": undefined,
			"revelioEnabled": undefined,
			"arithmancyEnabled": undefined,
			"revelioForceSubmit": undefined,
			"arithmancyForceSubmit": undefined,
			"revelioAllowSubmit": undefined,
			"arithmancyAllowSubmit": undefined,
			"providerTriageKeyword": []
		};
	}

}
