import { Injectable } from '@angular/core';
import { AmplifyService } from 'aws-amplify-angular';
import { APIClass } from 'aws-amplify';
import { API } from '@classes/api';
export { API } from '@classes/api';
import { config } from '../../config';
import { OfflineAuthService } from '@services/offlineAuth.service';
import { AuthService } from '@services/auth.service';
import { User } from '@classes/user';

@Injectable({ providedIn: 'root' })
export class RestService {

	private api: APIClass;

	constructor(
		private amplifyService: AmplifyService,
		private offlineAuthService: OfflineAuthService,
		private authService: AuthService) {
		this.api = amplifyService.api();
	}

	/**
	 * Adds an 'x-identityId' value to the HTTP request headers if we're running in a serverless-offline
	 * environment. This acts as a shim for AWS_IAM authentication which is not supported by serverless-offline.
	 *
	 * @param requestOptions The options for the request
	 * @return The original request options object, with an additional header object appended if required.
	 */
	private addOfflineAuthHeader(requestOptions: any): any {
		if (this.offlineAuthService.isOffline) {
			requestOptions = requestOptions || {};
			requestOptions.headers = requestOptions.headers || {};
			requestOptions.headers['x-identityId'] = this.offlineAuthService.getIdentity();
		}

		return requestOptions;
	}

	/**
	 * Adds an 'x-identityId' value to the HTTP request headers if we're running in a serverless-offline
	 * environment. This acts as a shim for AWS_IAM authentication which is not supported by serverless-offline.
	 *
	 * Adds an 'x-pm' header to indentify the plan manager that an admin is assuming the identity of.
	 *
	 * @param requestOptions The options for the request
	 * @return The original request options object, with an additional header object appended if required.
	 */
	private bundleHeaders(requestOptions: any): any {
		const isMarauderAdmin = User.isMarauderAdmin(this.authService.currentUser);

		requestOptions = requestOptions || {};
		requestOptions.headers = requestOptions.headers || {};

		// If the "planManager" param is specified, get rid of it now so it's not sent to the server
		delete requestOptions.planManager;

		if (this.offlineAuthService.isOffline) {
			requestOptions.headers['x-identityId'] = this.offlineAuthService.getIdentity();
		}

		return requestOptions;
	}

	/**
	 * Wrapper for the amplifyService.api().get method.
	 *
	 * @param api The name of the API to call. This is defined in aws-settings.ts
	 * @param path The request path
	 * @param options Extra request params (see https://aws-amplify.github.io/docs/js/api#get)
	 * @return A promise that resolves with the result of the HTTP request
	 */
	async get(api: API, path: string, options?: any): Promise<any> {
		const name = API.toString(api);
		const response = this.api.get(name, path, this.bundleHeaders(options));
		return response;
	}

	/**
	 * Wrapper for the amplifyService.api().post method.
	 *
	 * @param api The name of the API to call. This is defined in aws-settings.ts
	 * @param path The request path
	 * @param body The data to be POSTed with the request
	 * @param options Extra request params (see https://aws-amplify.github.io/docs/js/api#post)
	 * @return A promise that resolves with the result of the HTTP request
	 */
	async post(api: API, path: string, body: any, options?: any): Promise<any> {
		options = options || {};
		options.body = body;
		return await this.api.post(API.toString(api), path, this.bundleHeaders(options));
	}


	/**
	 * Wrapper for the amplifyService.api().put method.
	 *
	 * @param api The name of the API to call. This is defined in aws-settings.ts
	 * @param path The request path
	 * @param body The data to be sent with the request
	 * @param options Extra request params (see https://aws-amplify.github.io/docs/js/api#post)
	 * @return A promise that resolves with the result of the HTTP request
	 */
	async put(api: API, path: string, body: any, options?: any): Promise<any> {
		options = options || {};
		options.body = body;
		return await this.api.put(API.toString(api), path, this.bundleHeaders(options));
	}

	/**
	 * Wrapper for the amplifyService.api().post method.
	 *
	 * @param api The name of the API to call. This is defined in aws-settings.ts
	 * @param path The request path
	 * @param options Extra request params (see https://aws-amplify.github.io/docs/js/api#post)
	 * @return A promise that resolves with the result of the HTTP request
	 */
	async delete(api: API, path: string, options?: any): Promise<any> {
		options = options || {};
		return await this.api.del(API.toString(api), path, this.bundleHeaders(options));
	}

	/**
	 * Wrapper for the amplifyService.api().patch method.
	 *
	 * @param api The name of the API to call. This is defined in aws-settings.ts
	 * @param path The request path
	 * @param body The data to be sent with the request
	 * @param options Extra request params (see https://aws-amplify.github.io/docs/js/api#post)
	 * @return A promise that resolves with the result of the HTTP request
	 */
	async patch(api: API, path: string, body: any, options?: any): Promise<any> {
		options = options || {};
		options.body = body;
		return await this.api.patch(API.toString(api), path, this.bundleHeaders(options));
	}

	/**
	 * Wrapper for the amplifyService.api().head method.
	 *
	 * @param api The name of the API to call. This is defined in aws-settings.ts
	 * @param path The request path
	 * @param options Extra request params (see https://aws-amplify.github.io/docs/js/api#post)
	 * @return A promise that resolves with the result of the HTTP request
	 */
	async head(api: API, path: string, options?: any): Promise<any> {
		options = options || {};
		return await this.api.head(API.toString(api), path, this.bundleHeaders(options));
	}
}
