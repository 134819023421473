import { Cache } from 'aws-amplify';
import { WebsocketState } from "@classes/websocketHelpers";

interface PromiseResult {
	"resolve": (any) => any;
	"reject": () => any;
}

interface StorageAPI {
	set: (values: any | any[]) => Promise<any>;
	get: (key: string) => Promise<any>;
	remove: (keyOrRange: string|IDBKeyRange) => Promise<void>;
	clear: () => Promise<void>;
	query: (indexName: string, keyRange: IDBKeyRange) => Promise<any[]>;
}

interface KeyStorageAPI {
	set: (keys: string | string[], values: any | any[]) => Promise<any>;
	get: (key: string) => Promise<any>;
	remove: (keyOrRange: string|IDBKeyRange) => Promise<void>;
	clear: () => Promise<void>;
}

/**
* IndexDB Storage wrapper
*/
export class StorageService {
	private static db: IDBDatabase;
	private static connectionRequest: IDBOpenDBRequest;

	private static readonly dbName = 'marauders';
	//private static readonly dbVersion = 7;
	//private static readonly dbVersion: number = 10; //environment.appVersion;
	public static readonly dbVersion: number = 11; //environment.appVersion;

	private static connectQueue: PromiseResult[] = [];

	private static init(): Promise<IDBDatabase> {

		// Do not proceed if a websocket is not available.
		if (!WebsocketState.isOpen) {
			return Promise.reject("Websockets required for indexedDB");
		}

		if (!('indexedDB' in window)) {
			return Promise.reject("Your browser doesn't support indexedDB");
		}

		if (StorageService.db) {
			return Promise.resolve(StorageService.db);
		}

		return new Promise<IDBDatabase>((resolve, reject) => {

			StorageService.connectQueue.push({"resolve": resolve, "reject": reject});
			if (StorageService.connectQueue.length > 1) {
				return;
			}

			StorageService.connectionRequest = indexedDB.open(StorageService.dbName, StorageService.dbVersion);

			StorageService.connectionRequest.onupgradeneeded = (event: any) => {
				const db = event.target.result;

				["entities", "general", "nonvolatile", "supports"].forEach( objectStoreName => {
					try {
						db.deleteObjectStore(objectStoreName);
					}
					catch (e) {
						// Ignore - probably didn't exist in the first place
					}
				});

				const entities = db.createObjectStore("entities", {"keyPath": "id"});
				entities.createIndex("id", "id", { "unique": true });

				const supports = db.createObjectStore("supports", {"keyPath": "id"});
				supports.createIndex("id", "id", { "unique": true });
				supports.createIndex("region", "region", { "unique": false });
				supports.createIndex("plan", ["region", "dateFrom"], { "unique": false });
				supports.createIndex("support", ["region", "dateFrom", "supportItemNumber"], { "unique": true });
				// supports.createIndex("plan", ["dateFrom", "dateTo", "region"], { "unique": false });
				supports.createIndex("supportItemNumber", ["region", "supportItemNumber"], { "unique": false });

				db.createObjectStore("general");
				db.createObjectStore("nonvolatile");
			};

			StorageService.connectionRequest.onsuccess = () => {
				StorageService.db = StorageService.connectionRequest.result;
				StorageService.connectionRequest = undefined;
				while (StorageService.connectQueue.length) {
					const promise = StorageService.connectQueue.shift();
					promise.resolve(StorageService.db);
				}
			}
		});
	}

	private static clear(objectStore: string): Promise<void> {
		return new Promise((resolve, reject) => {
			StorageService
				.init()
				.then( db => {
					const transaction = db.transaction(objectStore, "readwrite");
					const entitiesStore = transaction.objectStore(objectStore);
					transaction.oncomplete = () => {
						resolve();
					};

					entitiesStore.clear();

				}, (e) => {
					console.log(e);
					resolve();
				});
		});
	}

	private static keyset(objectStore: string, keys: string | string[], values: any | any[]): Promise<any> {

		const _keys = Array.isArray(keys) ? [...keys] : [keys];
		const _values = Array.isArray(keys) ? [...values] : [values];
		const singleItem = _keys.length === 1;

		if (_keys.length !== _values.length) {
			return Promise.reject("Number of keys does not match number of values");
		}

		return new Promise((resolve, reject) => {
			const completed = () => { resolve(values); };

			StorageService
				.init()
				.then( db => {
					const transaction = db.transaction(objectStore, "readwrite");
					const store = transaction.objectStore(objectStore);

					if (singleItem) {
						store.put( _values.pop(), _keys.pop()).onsuccess = completed;
					}
					else {
						transaction.oncomplete = completed
						while (_values.length > 0) {
							store.put( _values.pop(), _keys.pop());
						}
					}

				}, (e) => { console.log(e); resolve(values); } );
		});
	}

	private static set(objectStore: string, values: any | any[]): Promise<any> {

		const _values = Array.isArray(values) ? [...values] : [values];
		const numItems = _values.length;
		const singleItem = numItems === 1;

		if (numItems === 0) {
			return Promise.resolve(values);
		}

		return new Promise((resolve, reject) => {
			const completed = () => { resolve(values); };

			StorageService
				.init()
				.then( db => {
					const transaction = db.transaction(objectStore, "readwrite");
					const store = transaction.objectStore(objectStore);

					if (singleItem) {
						store.put( _values.pop() ).onsuccess = completed;
					}
					else {
						transaction.oncomplete = completed;

						while (_values.length > 0) {
							store.put( _values.pop() );
						}
					}

				}, (e) => { console.log(e); resolve(values); } );
		});
	}

	private static query(objectStore: string, indexName: string, keyRange: IDBKeyRange): Promise<any[]> {

		return new Promise((resolve, reject) => {

			let result: any[] = [];

			StorageService
				.init()
				.then( db => {
					const transaction = db.transaction(objectStore, "readonly");
					const store = transaction.objectStore(objectStore);

					const request = store.index(indexName).getAll(keyRange);
					request.onsuccess = () => {
						resolve(request.result);
					};

				}, (e) => { console.log(e); resolve(undefined); } );
		});
	}

	private static remove(objectStore: string, keyOrRange: string|IDBKeyRange): Promise<void> {
		return new Promise((resolve, reject) => {
			StorageService
				.init()
				.then( db => {
					const transaction = db.transaction(objectStore, "readwrite");
					const entitiesStore = transaction.objectStore(objectStore);
					const request = entitiesStore.delete(keyOrRange);
					request.onsuccess = () => {
						resolve();
					}
				}, () => resolve() );
		});
	}

	private static get(objectStore: string, key: string): Promise<any> {
		if (!key || key === null) {
			// handed an empty key, so don't try to return anything
			return Promise.reject();
		}
		return new Promise((resolve, reject) => {
			StorageService
				.init()
				.then( db => {
					const transaction = db.transaction(objectStore, "readonly");
					const entitiesStore = transaction.objectStore(objectStore);
					const request = entitiesStore.get(key);
					request.onsuccess = () => {
						resolve(request.result);
					}
				}, () => resolve(undefined) );
		});
	}

	public static get entities(): StorageAPI {
		const objectStoreName = 'entities';
		return {
			"get": StorageService.get.bind(undefined, objectStoreName),
			"set": StorageService.set.bind(undefined, objectStoreName),
			"remove": StorageService.remove.bind(undefined, objectStoreName),
			"clear": StorageService.clear.bind(undefined, objectStoreName),
			"query": StorageService.query.bind(undefined, objectStoreName)
		};
	}

	public static get general(): KeyStorageAPI {
		const objectStoreName = 'general';
		return {
			"get": StorageService.get.bind(undefined, objectStoreName),
			"set": StorageService.keyset.bind(undefined, objectStoreName),
			"remove": StorageService.remove.bind(undefined, objectStoreName),
			"clear": StorageService.clear.bind(undefined, objectStoreName)
		};
	}

	public static get nonVolatile(): KeyStorageAPI {
		const objectStoreName = 'nonvolatile';
		return {
			"get": StorageService.get.bind(undefined, objectStoreName),
			"set": StorageService.keyset.bind(undefined, objectStoreName),
			"remove": StorageService.remove.bind(undefined, objectStoreName),
			"clear": StorageService.clear.bind(undefined, objectStoreName)
		};
	}

	public static get supports(): StorageAPI {
		const objectStoreName = 'supports';
		return {
			"get": StorageService.get.bind(undefined, objectStoreName),
			"set": StorageService.set.bind(undefined, objectStoreName),
			"remove": StorageService.remove.bind(undefined, objectStoreName),
			"clear": StorageService.clear.bind(undefined, objectStoreName),
			"query": StorageService.query.bind(undefined, objectStoreName)
		};
	}
}

/**
* Simple wrapper around the AWS Amplify Cache class.
* Provides client-side session storage using key-based access to stored values.
* Whilst labelled as a service, interaction is purely through static class methods, so
* there is no need to construct an instance of this class.
*/
export class SessionStorageService {

	private static _storage: any = undefined;

	private constructor() {};

	private static initCache(): void {
		if (SessionStorageService._storage === undefined) {
			const config = {
				"keyPrefix": "dx",
				"storage": window.sessionStorage
			};
			SessionStorageService._storage = Cache.createInstance(config);
		}

		return SessionStorageService._storage;
	}

	private static get storage(): any {
		return SessionStorageService._storage ?? SessionStorageService.initCache();
	}

	/**
	* Stores a value against a key in the cache
	* @param {string} key The name used to reference the stored item
	* @param {any} value The value to store
	*/
	public static set(key: string, value: any): void {
		SessionStorageService.storage.setItem(key, value);
	}

	/**
	* Removes a value from the cache
	* @param {string} key The name used to reference the stored item
	*/
	public static remove(key: string): void {
		SessionStorageService.storage.removeItem(key);
	}

	/**
	* Retrieves a value from the cache
	* @param {string} key The name of the item to retrieve
	* @param {boolean} clear (Optional) Specifies whether the value should be removed from the cache
	* @return {any} The cached value
	*/
	public static get(key: string, clear?: boolean): any {
		const result = SessionStorageService.storage.getItem(key);
		if (!!clear) {
			SessionStorageService.storage.removeItem(key);
		}
		return result;
	}

	/**
	* Removes all items stored in the cache
	*/
	public static clear() {
		SessionStorageService.storage.clear();
	}
}
